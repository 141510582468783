function setup_flash_dismissal() {
    document.body.addEventListener('click', (e) => {
        const close_btn = e.target.closest('.close, .js-flash-close');

        if (close_btn) {
            const flash_el = close_btn.closest('.flash');

            if (flash_el) {
                flash_el.remove();
            }
        }
    });
}

document.addEventListener('DOMContentLoaded', setup_flash_dismissal);
